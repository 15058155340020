@import "@vmf/components/src/assets/style/_index.scss";












































































































.aside-menu-item {
    position: relative;
    > .link {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
.layout-menu-aside {
    background-color: $gray8;
    @include beautify-scrollbar;

    > .el-menu {
        border-right: none;
        &:not(.el-menu--collapse) {
            min-width: 200px;
            //min-height: 400px;
        }

        &,
        .el-menu {
            background-color: $gray8;
        }
        .el-menu-item {
            color: #fff;

            &:focus,
            &:hover {
                background-color: mix(#000, $gray8, 10%);
                border-bottom: 0;
            }
            &.is-active {
                color: #82e6e6;
                background-color: mix(#000, $gray8, 20%);
                border-bottom: 0;
            }
        }

        .el-submenu {
            .x-icon {
                margin-right: $margin-small;
            }
            &.is-active {
                > .el-submenu__title {
                    color: #82e6e6;
                    i {
                        color: #82e6e6;
                    }
                }
            }
        }

        .el-submenu__title {
            &,
            i {
                color: #fff;
            }
            &:not(.is-disabled):hover {
                background-color: mix(#000, $gray8, 10%);
            }
        }
    }
    > .el-menu.el-menu--collapse {
        width: auto;
        .el-submenu {
            > .el-submenu__title {
                padding: 0 $padding !important;
            }
        }
    }
}
