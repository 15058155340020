@import "@vmf/components/src/assets/style/_index.scss";






































































.notice-message {
    padding: $padding;

    + .notice-message {
        border-top: 1px solid $color-border;
    }

    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .label {
            flex: 1;
            font-size: 120%;
        }

        > .time {
            flex: 0 0 auto;
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > .sn {
            flex: 1;

            > div {
                display: inline-block;
                color: $color-primary;
                cursor: pointer;
            }
        }

        > .status {
            flex: 0 0 auto;
        }
    }
}
