@import "@vmf/components/src/assets/style/_index.scss";













































































































































































































































































html.pc {
    .layout-menu {
        min-width: 1280px;
    }
}
.layout-menu {
    height: 100%;
}
