@import "@vmf/components/src/assets/style/_index.scss";




















































































.nav-menu {
    flex: 1 1 1%;
    overflow-x: auto;
    overflow-y: hidden;
    @include beautify-scrollbar;

    > .el-menu {
        flex: 1 1 1%;
        border-bottom: none;
        background-color: transparent;

        &.el-menu--horizontal {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            > .el-menu-item {
                float: none;
                height: auto;
                line-height: 0;
                padding: 0;
                border-bottom: 0;

                > .link {
                    display: inline-block;
                    height: 60px;
                    line-height: 60px;
                    padding: 0 20px;
                }
            }
        }

        > .el-submenu,
        > .el-menu-item {
            background-color: transparent;

            .x-icon {
                margin-right: $margin-small;
            }

            &,
            > .el-submenu__title {
                &,
                i {
                    color: #fff;
                }
            }

            &:not(.is-disabled) {
                &:focus {
                    &,
                    > .el-submenu__title {
                        background-color: transparent;
                        color: #fff;
                    }
                }
                &:hover {
                    &,
                    > .el-submenu__title {
                        background-color: mix(#000, #125454, 5%);
                        color: #fff;
                    }
                }
            }

            &.is-active {
                &,
                > .el-submenu__title {
                    background-color: mix(#000, #125454, 10%);
                    color: #fff;
                    border-bottom: 0;

                    i {
                        //color: #125454;
                    }
                }
            }
        }
    }
}
