@import "@vmf/components/src/assets/style/_index.scss";




















































































.tag-picker {
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    > .item {
        position: relative;
        vertical-align: middle;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        background-color: #f00;

        > .mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgb(0, 0, 0, 0.3);
        }

        &.active {
            > .mask {
                display: none;
            }
        }
    }
}
