@import "@vmf/components/src/assets/style/_index.scss";




















































































































.layout-menu-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    background-color: #125454;

    > .brand {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 200px;
        color: #fff;
        font-size: 26px;
        letter-spacing: 8px;
        font-weight: bold;
        background: transparent center no-repeat;
        background-size: contain;
        > .inner {
            line-height: 1.2;
            @include text-line(1);
        }
    }

    // 子组件
    > .collapse-btn,
    > .remainder-btn {
        padding: 0 $padding;
        min-width: 44px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
    }

    > .user-menu,
    > .remainder-menu {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 $padding;
        color: #fff;
    }
}
