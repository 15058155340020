@import "@vmf/components/src/assets/style/_index.scss";

















































































































































































































































































































































































































































































































.layout-menu {
    height: 100%;
    width: 100%;
}

.notice {
    position: fixed;
    /*left: 0;*/
    right: 0;
    top: 160px;
    background-color: $color-primary;
    padding: $padding;
    border-top-left-radius: $padding-small;
    border-bottom-left-radius: $padding-small;

    > .icon {
        height: 1em;
        width: 1em;
        display: block;
        font-size: 20px;
        color: #fff;
    }

    > .dot {
        background-color: #f00;
        width: 10px;
        height: 10px;
        border-radius: 100vw;
        position: absolute;
        top: $padding-small;
        left: $padding-small;
    }
}
