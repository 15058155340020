.remote-select{
    &.size-350{
        >.el-select{
            width: 350px;
        }
    }
    &.size-400{
        >.el-select{
            width: 400px;
        }
    }
}
