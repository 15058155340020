@import "@vmf/components/src/assets/style/_index.scss";




























































































































.x-address-warp {
    color: $color-blue;
    display: inline;
    cursor: pointer;
    //white-space: nowrap;
}
